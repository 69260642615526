<template>
    <div id="courseTeaching">
        <div class="box">
            <div style="width: 100%;height: 60px;display: flex;justify-content: space-between;margin-top: 10px;">
            <div>
                <div v-for="(item,index) in daohangData" :key="index"  :class="indexYs == index?'whiteData':'default'" @click="daohangClick(index)">
                    {{ item.name }}
                </div>
            </div>
            <div>
                <!-- <el-button type="primary" round style="background-color: #76C8F6;border: none;" >编辑课程</el-button> -->
                <!-- <el-button type="primary" round style="background-color: #76C8F6;border: none;">开始上课</el-button> -->
            </div>
          </div>
          <div class="boxCneterData" >
            <p style="padding-top: 20px;">
                <!-- <span style="font-size: 16px;font-weight: 600;color: #76C8F6;;padding-left: 20px;">|</span><span style="padding-left: 10px;font-weight: 600;">成绩</span> -->
                <span style="font-weight: 800;font-size: 20px;cursor: pointer;" class="el-icon-arrow-left" @click="blackClick"></span><span style="padding-left: 10px;font-weight: 600;">成绩</span>
            </p>
            
           
            <div style="margin-top: 10px;">
                    <el-input style="width: 200px;margin-left: 10px;margin-right: 10px;" size="mini"  v-model="studentName" placeholder="学生姓名" />
                    <el-select
                    style="margin-right: 10px;"
                        size="mini"
                        v-model="whetherOrNot"
                        placeholder="是否通过"
                        clearable>
                        <el-option
                        v-for="item in whetherOrNottions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                    <el-button type="primary" size="mini" @click="serachClick">搜索</el-button>
                </div>
            <el-table :data="tableData" stripe style="width: 100%;height:500px" max-height="500px">
            <el-table-column type="index" label="序号" width="50" />
            <el-table-column prop="realName" label="学生姓名"  />
            <el-table-column prop="insertTime" label="交卷时间" />
            <el-table-column prop="accuracy" label="正确率" >
                <template #default="{row}">
                   {{`${row.accuracy}%`}}
                </template>
            </el-table-column>
            <el-table-column prop="score" label="分数" />
            <!-- 0、待考试  1、及格  2、不及格 -->
            <el-table-column prop="paperStatus" label="是否通过"  >
                <template #default="{row}">
                <span v-if="row.paperStatus == 0">待考试</span>
                <span v-if="row.paperStatus == 1">及格</span>
                <span v-if="row.paperStatus == 2">不及格</span>
              </template>
            </el-table-column>
            <el-table-column  label="操作" >
                <template #default="{row}">
                <span style="cursor: pointer;color:#76C8F6" @click="Preview(row)">试卷预览</span>
              </template>
            </el-table-column>

          </el-table>
          <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
            <el-pagination v-if="total>5" style="margin: 0 auto;text-align: center;" :page-size="pageSize" :current-page="currentPage" layout="prev, pager, next" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
        </div>
        </div>

        <el-dialog
            v-model="centerDialogVisible"
            title="试卷预览"
            width="60%"
            align-center
        >
            <div
                style=" width: 100%;height: 30px;background: #fff;border-top: 0.5px solid #edeff2;line-height: 30px;display: flex;align-items: center;">
                <div style="font-size:14px;margin:0 0 0 20px;">
                    <span style="color:#666;">学生姓名：</span>
                    <span style="color:#333;">{{ rowname }}</span>
                </div>
            </div>
            <div style="width: 100%;height: 700px;overflow-y: auto;">
                    <!--  -->
                    <p style="font-size: 16px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        color: #333333;" v-if="Fillintheblanks.length != 0">一、填空题</p>
                    <div v-if="Fillintheblanks.length != 0">
                        <div style="background-color: #fff;margin-top: 10px;padding: 10px;"
                            v-for="(item, index) in Fillintheblanks" :key="index">
                            <p style="font-size: 14px;">
                                <span
                                    style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                                <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                                <!-- 是否正确  0、不做统计  1、正确  2、错误  3、空白 :style="item.isCorrect == 2 ? 'color:#F50000;' : 'color:#76C8F6;'"-->
                                <span >
                                    <span style="margin:0px 20px;" :style="item.isCorrect == 1 ? 'color:#76C8F6;' : item.isCorrect == 2 ? 'color:#F50000;' : item.isCorrect == 3 ? 'color:#F50000' : 'color:#F50000'">
                                        <span>学生答案：</span>
                                        <span>{{item.questionAnswerContent}}</span>
                                    </span>
                                    <span style="margin:0px 20px;color:#76C8F6;">
                                        <span>正确答案：</span>
                                        <span>{{answer(item.answers)}}</span>
                                    </span>
                                </span>
                                
                            </p>
                            <el-input placeholder="" size="mini" style="margin-top: 10px;"
                                v-for="(item2, index2) in item.answers" :key="index2" v-model="item2.dev">
                                <template #prepend>{{ index2+=1 }}</template>
                            </el-input>
                        </div>
                    </div>
                    <!--  -->
                    <p style="font-size: 16px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        color: #333333;padding-top: 10px;" v-if="SingleSelection.length != 0">二、单选题</p>
                    <div v-if="SingleSelection.length != 0">
                        <div style="background-color: #fff;margin-top: 10px;padding: 10px;"
                            v-for="(item, index) in SingleSelection" :key="index">
                            <p style="font-size: 14px;">
                                <span
                                    style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                                <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                                 <!-- 是否正确  0、不做统计  1、正确  2、错误  3、空白 :style="item.isCorrect == 2 ? 'color:#F50000;' : 'color:#76C8F6;'"-->
                                 <span >
                                    <span style="margin:0px 20px;" :style="item.isCorrect == 1 ? 'color:#76C8F6;' : item.isCorrect == 2 ? 'color:#F50000;' : item.isCorrect == 3 ? 'color:#F50000' : 'color:#F50000'">
                                        <span>学生答案：</span>
                                        <span>{{yesanswer2(item.questionAnswer)}}</span>
                                    </span>
                                    <span style="margin:0px 20px; color:#76C8F6;">
                                        <span>正确答案：</span>
                                        <span>{{answer2(item.answers)}}</span>
                                    </span>
                                </span>
                            </p>
                            <el-radio-group v-model="item.dev" style="margin-top: 10px;">
                                <el-radio v-for="(item2, index2) in item.answers" :key="index2" :label="item2.isOrder">
                                    {{ item2.isOrder == 1 ? 'A' : item2.isOrder == 2 ? 'B' : item2.isOrder == 3 ? 'C' : item2.isOrder == 4 ? 'D' : '' }}  {{ item2.content }}
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <!--  -->
                    <p style="font-size: 16px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        color: #333333;padding-top: 10px;" v-if="Multiple.length != 0">三、多选题</p>
                    <div v-if="Multiple.length != 0">
                        <div style="background-color: #fff;margin-top: 10px;padding: 10px;" v-for="(item, index) in Multiple"
                            :key="index">
                            <p style="font-size: 14px;">
                                <span
                                    style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                                <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                                 <!-- 是否正确  0、不做统计  1、正确  2、错误  3、空白 :style="item.isCorrect == 2 ? 'color:#F50000;' : 'color:#76C8F6;'"-->
                                 <span >
                                    <span style="margin:0px 20px;" :style="item.isCorrect == 1 ? 'color:#76C8F6;' : item.isCorrect == 2 ? 'color:#F50000;' : item.isCorrect == 3 ? 'color:#F50000' : 'color:#F50000'">
                                        <span>学生答案：</span>
                                        <span>{{yesanswer2(item.questionAnswer)}}</span>
                                    </span>
                                    <span style="margin:0px 20px; color:#76C8F6;">
                                        <span>正确答案：</span>
                                        <span>{{answer2(item.answers)}}</span>
                                    </span>
                                </span>
                            </p>
                            <el-checkbox-group v-model="item.dev">
                                <el-checkbox v-for="(item2, index2) in item.answers" :key="index2" :label="item2.isOrder">
                                    {{ item2.isOrder == 1 ? 'A' : item2.isOrder == 2 ? 'B' : item2.isOrder == 3 ? 'C' : item2.isOrder == 4 ? 'D' : '' }}  {{ item2.content }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                    <!--  -->
                    <p style="font-size: 16px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        color: #333333;padding-top: 10px;" v-if="Judgment.length != 0">四、判断题</p>
                    <div v-if="Judgment.length != 0">
                        <div style="background-color: #fff;margin-top: 10px;padding: 10px;" v-for="(item, index) in Judgment"
                            :key="index">
                            <p style="font-size: 14px;">
                                <span
                                    style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                                <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                                 <!-- 是否正确  0、不做统计  1、正确  2、错误  3、空白 :style="item.isCorrect == 2 ? 'color:#F50000;' : 'color:#76C8F6;'"-->
                                 <span>
                                    <span style="margin:0px 20px;" :style="item.isCorrect == 1 ? 'color:#76C8F6;' : item.isCorrect == 2 ? 'color:#F50000;' : item.isCorrect == 3 ? 'color:#F50000' : 'color:#F50000'">
                                        <span>学生答案：</span>
                                        <span>{{yesanswer2(item.questionAnswer)}}</span>
                                    </span>
                                    <span style="margin:0px 20px; color:#76C8F6;">
                                        <span>正确答案：</span>
                                        <span>{{answer2(item.answers)}}</span>
                                    </span>
                                </span>
                            </p>
                            <el-radio-group v-model="item.dev" style="margin-top: 10px;">
                                <el-radio v-for="(item2, index2) in item.answers" :key="index2"
                                    :label="item2.isOrder">
                                    {{ item2.isOrder == 1 ? 'A' : item2.isOrder == 2 ? 'B' : item2.isOrder == 3 ? 'C' : item2.isOrder == 4 ? 'D' : '' }}  {{ item2.content }}
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                </div>




        </el-dialog>


    </div>
</template>


<script>
import { reactive, toRefs, onMounted, onUnmounted, computed,watch ,defineProps} from "vue";
import { useRouter,useRoute } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {backUrl} from "@/js/util"
import * as echarts from 'echarts';
import {f_get_classNumExaminationDataPageList} from '@/js/classManagement'
import {f_get_examinationPaperPreview} from "@/js/homeListApi.js"
import { ElMessage } from 'element-plus';
export default {
    
    setup (props,ctx) {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
       
        const state = reactive({
            studentName:'',
            whetherOrNot:1,
            whetherOrNottions:[
                {
                    label:'通过',
                    value:1
                },{
                    label:'未通过',
                    value:2
                }
            ],
            pageSize:5,
            currentPage:1,
            total:0,
            formInline:{
                user:'',
                region:''
            },
            tableData:[],
            radio1:'1',
            indexYs:2,
            daohangData:[
                {
                    name:'数据'
                },{
                    name:'课后作业'
                },{
                    name:'考试'
                },{
                    name:'上课时间'
                },
            ],
            centerDialogVisible:false,
            Fillintheblanks: [],//填空
            SingleSelection: [],//单选
            Multiple: [],//多选
            Judgment: [],//判断
            rowname:'',
           
        })


        const methods ={
            answer(answers){
                let arr = [];
                answers.forEach((res)=>{
                    if (res.isAnswer == true) {
                        arr.push(res.content)
                    }
                })
                return arr.join(',')
            },
            yesanswer(questionAnswer){
                let arr = [];
                questionAnswer.forEach((res)=>{
                    arr.push(res.content)
                })
                return arr.join(',')
            },
            //正确答案：
            answer2(answers){
                let arr = [];
                answers.forEach((res)=>{
                    if (res.isAnswer == true) {
                        if (res.isOrder == 1) {
                            arr.push("A")
                        } else if (res.isOrder == 2) {
                            arr.push("B")
                        } else if (res.isOrder == 3) {
                            arr.push("C")
                        } else if (res.isOrder == 4) {
                            arr.push("D")
                        }
                    }
                })
                return arr.join(',')
            },
            //学生答案：
            yesanswer2(questionAnswer){
                let arr = [];
                questionAnswer.forEach((res)=>{
                    if (res.isOrder == 1) {
                        arr.push("A")
                    } else if (res.isOrder == 2) {
                        arr.push("B")
                    } else if (res.isOrder == 3) {
                        arr.push("C")
                    } else if (res.isOrder == 4) {
                        arr.push("D")
                    }
                })
                return arr.join(',')
            },
            Preview(row){
                state.rowname = row.realName
                let datas = {
                    examinationPaperId:row.id
                }
                f_get_examinationPaperPreview(datas).then((res)=>{
                    if (res.data.code == 1) {
                        // console.log(res.data,'石星雨');
                        let arr = [];
                        state.SingleSelection = [];
                        state.Multiple = [];
                        state.Fillintheblanks = [];
                        state.Judgment = [];
                        res.data.data.forEach(item1 => {
                            item1.forEach((item2)=>{
                                arr.push(item2)
                            })
                        });
                        arr.forEach(item => {
                            if (item.topicType == 1) {
                                state.SingleSelection.push({
                                    questionAnswer:item.questionAnswer,
                                    isCorrect:item.isCorrect,
                                    answers: item.answers,
                                    isOrder:item.isOrder,
                                    id: item.id,
                                    testQuestionContent: item.testQuestionContent,
                                    testQuestionAnalysis: item.testQuestionAnalysis,
                                    topicType: item.topicType,
                                    dev: ''
                                })
                            } else if (item.topicType == 2) {
                                state.Multiple.push({
                                    questionAnswer:item.questionAnswer,
                                    isCorrect:item.isCorrect,
                                    answers: item.answers,
                                    isOrder:item.isOrder,
                                    id: item.id,
                                    testQuestionContent: item.testQuestionContent,
                                    testQuestionAnalysis: item.testQuestionAnalysis,
                                    topicType: item.topicType,
                                    dev: []
                                })
                            } else if (item.topicType == 3) {
                                state.Fillintheblanks.push({
                                    questionAnswer:item.questionAnswer,
                                    isCorrect:item.isCorrect,
                                    answers: item.answers,
                                    isOrder:item.isOrder,
                                    id: item.id,
                                    testQuestionContent: item.testQuestionContent,
                                    testQuestionAnalysis: item.testQuestionAnalysis,
                                    topicType: item.topicType,
                                    questionAnswerContent:item.questionAnswerContent,
                                    dev: ''
                                })
                            } else if (item.topicType == 4) {
                                state.Judgment.push({
                                    questionAnswer:item.questionAnswer,
                                    isCorrect:item.isCorrect,
                                    answers: item.answers,
                                    isOrder:item.isOrder,
                                    id: item.id,
                                    testQuestionContent: item.testQuestionContent,
                                    testQuestionAnalysis: item.testQuestionAnalysis,
                                    topicType: item.topicType,
                                    dev: ''
                                })
                            }
                        });

                        state.centerDialogVisible = true;

                    }else{
                        ElMessage.error(res.data.message)
                    }
                })

            },
            // 获取详情数据
            f_get_classNumExaminationDataPageList(){
                let params = {
                    examinationId:route.query.examinationId,
                    classNumId:getStorage('classDetailData').id,
                    name:state.studentName,
                    paperStatus:state.whetherOrNot,
                    pageIndex:state.currentPage,
                    pageSize:state.pageSize
                }
                console.log(state.whetherOrNot);
                f_get_classNumExaminationDataPageList(params).then(res=>{
                    if (res.data.code == 1) {
                        state.tableData = res.data.data.list;
                        state.total = res.data.data.totalCount.Value;
                    }
                })
            },
            serachClick(){
                state.currentPage = 1;
                methods.f_get_classNumExaminationDataPageList();
            },
            handleSizeChange(val){
                state.pageSize = val;
                methods.f_get_classNumExaminationDataPageList();
            },
            handleCurrentChange(val){
                state.currentPage = val;
                methods.f_get_classNumExaminationDataPageList();
            },
            // blackClick(){
            //     router.push('/home/afterClassExercises')
            // },
            blackClick(){
                // router.push('/home/courseList')
                router.go(-1)
            },

            daohangClick(index){
                console.log(index);
                state.indexYs = index;
                if (state.indexYs == 0) {
                    router.push('/home/classDetails')
                }else if(state.indexYs == 1){
                    router.push('/home/afterClassExercises')
                }else if(state.indexYs == 2){
                    router.push('/home/examinationIndex')
                }else if(state.indexYs == 3){
                    router.push('/home/classTime')
                }

            },
        }
        onMounted(() => {
            methods.f_get_classNumExaminationDataPageList();
            
        })
        return {
      ...toRefs(state),
      ...methods,
    };
    }
    }

</script>
<style  lang="scss" scoped>
#courseTeaching{
    width: 100%;
            height: 800px;
            background: #F7F8FA;;
            .box{
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
            }
            .whiteData{
            width: 85px;
            height:30px;
            float: left;
            margin: 10px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            cursor: pointer;
            background: #fff;
        }
        .default{
            width: 85px;
            height:30px;
            float: left;
            margin: 10px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            cursor: pointer;
            background: #EDEDED;
        }
        .boxCneterData{
            width: 100%;
            height: 800px;
            background: #fff;
        }
}

</style>